import React, { useState, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import introLarge from "../images/intro-bg.jpg";
import introSmall from "../images/intro-gb.jpg";
import ContactForm from "./ContactForm";
import "./Header.css";

function Loading() {
  const [introImage, setIntroImage] = useState(introLarge);
  const [showContactForm, setShowContactForm] = useState(false);


  const showPopup = () => {
    setShowContactForm(true);
    document.body.style.overflow = "hidden";
  };


  const hidePopup = () => {
    setShowContactForm(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIntroImage(introSmall);
      } else {
        setIntroImage(introLarge);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`loading position-relative ${
        showContactForm ? "blur-background" : ""
      }`}
    >
      <div className="banner-container">
        <img className="intro" src={introImage} alt="Introduction" />
      </div>
      <Container className="d-flex justify-content-between align-items-center gap-md-5 flex-column flex-md-row mt-3 mt-xl-4 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, x: -400 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.1 }}
        >
          <AnimationTitles title="Forest Themed Apartments in Budigere Cross." />
          <p className="header-para gray-90 mt-3 fs-5">
            <b>Godrej Properties,</b> A 39 floor high-rise apartment in East
            Bengaluru with a sprawling 28 acres of land parcel, with a massive
            central courtyard with 4 types of gardens. Godrej Bengal Lamps
            offers a perfect blend of luxury amenities and the natural
            tranquility of the surroundings.
          </p>
          <div className="header-btn">
            <Button
              className="brochure-btn m-0 my-3 px-5 py-2 fs-5 fw-bold"
              onClick={showPopup}
            >
              Contact Us
            </Button>
          </div>

          {showContactForm && (
            <div className="contact-form-popup">
              <div className="contact-form-container">
                <Button className="close-btn" onClick={hidePopup}>
                  &times;
                </Button>
                <ContactForm />
              </div>
            </div>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 400 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-100 my-5 cards-tilt"
        >
          <div className="tilt-images">
            <div className="cards">
              <Card className="bg-black-100 rounded">
                <Card.Body className="p-2">
                  <div className="rounded overflow-hidden position-relative">
                    <Card.Img
                      variant="top"
                      alt="img"
                      src={require("../images/image1.jpg")}
                    />
                  </div>

                  <div className="d-flex">
                    <div className="me-3">
                      <span className="gray-90"></span>
                    </div>
                    <div></div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="bg-black-100">
                <Card.Body className="p-2">
                  <div className="rounded overflow-hidden position-relative">
                    <Card.Img
                      variant="top"
                      alt="pic123"
                      src={require("../images/image2.jpg")}
                    />
                  </div>

                  <div className="d-flex">
                    <div className="me-3"></div>
                    <div></div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </motion.div>
      </Container>

      {/*
      {showContactForm && (
        <div className="contact-form-popup">
          <div className="contact-form-container">
            <Button className="close-btn" onClick={hidePopup}>
              &times;
            </Button>
            <ContactForm />
          </div>
        </div>
      )}

     
      <div className="fixed-contact-button">
        <Button
          className="brochure-btn m-0 my-3 px-5 py-2 fs-5 fw-bold"
          onClick={showPopup}
        >
          CONNECT
        </Button>
      </div>*/}
    </div>
  );
}

export default Loading;
