import { useState } from "react";
import { Container, Modal, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import "./Footer.css";

function Footer() {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);

  const handleCloseDisclaimer = () => setShowDisclaimer(false);
  const handleClosePrivacyPolicy = () => setShowPrivacyPolicy(false);
  const handleCloseTermsOfUse = () => setShowTermsOfUse(false);

  return (
    <footer>
      <Container className="conatiner-color">
        <div className="footer-wrap1 d-flex justify-content-between flex-column flex-md-row flex-wrap pb-4">
          <motion.div
            initial={{ y: -200 }}
            whileInView={{ y: 0 }}
            transition={{ duration: 0.5 }}
            className="footer-wrap"
          >
            <img
              src={require("../images/logo/logo.png")}
              alt="logo"
              className="footer-logo"
            />

            <div className="link-wrapper">
              <Button
                className="disc-btn"
                variant="link"
                onClick={() => setShowDisclaimer(true)}
              >
                Disclaimer
              </Button>
              <br />
              <Button
                className="disc-btn"
                variant="link"
                onClick={() => setShowPrivacyPolicy(true)}
              >
                Privacy Policy
              </Button>
              <Button
                className="disc-btn"
                variant="link"
                onClick={() => setShowTermsOfUse(true)}
              >
                Terms of use
              </Button>
            </div>
          </motion.div>
          <span className="line-space d-block d-md-none"></span>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="inspiraText flex-column flex-md-row flex-wrap gray-100 pt-3"
        >
          <div className="link-wrapper-phone">
            <Button
              className="disc-btn"
              variant="link"
              onClick={() => setShowDisclaimer(true)}
            >
              Disclaimer
            </Button>
            <br />
            <Button
              className="disc-btn"
              variant="link"
              onClick={() => setShowPrivacyPolicy(true)}
            >
              Privacy Policy
            </Button>
            <Button
              className="disc-btn"
              variant="link"
              onClick={() => setShowTermsOfUse(true)}
            >
              Terms of use
            </Button>
          </div>
        </motion.div>
      </Container>

      {/* Disclaimer Modal */}
      <Modal
        show={showDisclaimer}
        onHide={handleCloseDisclaimer}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton className="modal-header-sticky">
          <Modal.Title className="disc-btn">Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This disclaimer will be applicable to the website. By using or
          accessing the website you agree with the disclaimer without any
          qualification or limitation. The company reserves the right to add,
          alter or delete material from the website at any time and may, at any
          time, revise these terms without notifying you. You are bound by any
          such amendments and the company therefore advise that you periodically
          visit this page to review the current terms.
          <br></br>
          <br></br>
          The websites and all its content are provided with all faults on an
          "as is" and "as available" basis. No information given under this
          website creates a warranty or expand the scope of any warranty that
          cannot be disclaimed under applicable law. Your use of the website is
          solely at your own risk. This website is for guidance only. It does
          not constitute part of an offer or contract. Design & specifications
          are subject to change without prior notice. Computer generated images
          are the artist's impression and are an indicative of the actual
          designs.
          <br></br>
          <br></br>
          This is not an offer, an invitation to offer and/or commitment of any
          nature. The images includes artistic impressions and stock images. The
          Designs, dimensions, cost, facilities, plans, images, specifications,
          furniture, accessories, paintings, items, electronic goods, additional
          fittings/fixtures, decorative items, false ceiling including finishing
          materials, specifications, shades, sizes and colour of the tiles and
          other details shown in the images are only indicative in nature and
          are only for the purpose of illustrating/indicating a possible layout
          and do not form part of the standard specifications/amenities/services
          to be provided in the flat. Recipients are advised to use their
          discretion in relying on the information/amenities described/shown
          therein. All specifications of the flat shall be as per the final
          agreement between the Parties
          <br></br>
          <br></br>
          The particulars contained on the mentions details of the
          projects/developments undertaken by the company including depicting
          banners/posters of the project. The contents are being modified in
          terms of the stipulations / recommendations under the Real Estate
          Regulation Act, 2016 and rules made thereunder ("RERA") and
          accordingly may not be fully in line thereof as of date. You are
          therefore required to verify all the details, including area,
          amenities, services, terms of sales and payments and other relevant
          terms independently with the sales team/ company prior to concluding
          any decision for buying any unit(s) in any of the said projects. Till
          such time the details are fully updated, the said information will not
          be construed as an advertisement. To find out more about a project /
          development, please telephone our sales centre or visit our sales
          office during opening hours and speak to one of our sales staff.
          <br></br>
          <br></br>
          In no event will the company be liable for claim made by the users
          including seeking any cancellation for any of the inaccuracies in the
          information provided in this Website, though all efforts have to be
          made to ensure accuracy. The company under no circumstance will be
          liable for any expense, loss or damage including, without limitation,
          indirect or consequential loss or damage, or any expense, loss or
          damage whatsoever arising from use, or loss of use, of data, arising
          out of or in connection with the use of this website.
          <br></br>
          <br></br>
          The user/customer(s) acknowledge, agree and undertake to not hold the
          Company or any of its affiliates liable/responsible for any
          information stated, representation(s)/commitment(s)/offer(s) made by
          any third parties on their website to the user/customer(s) nor make
          any claims/demands on the Company or any of its affiliates with
          respect thereto.
        </Modal.Body>
        <Modal.Footer className="modal-footer-sticky "></Modal.Footer>
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal
        show={showPrivacyPolicy}
        onHide={handleClosePrivacyPolicy}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton className="modal-header-sticky">
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This Privacy Policy outlines (Godrej Bengal Lamps.site) its
          affiliates’s (“GBL”) approach to data protection and privacy to fulfil
          its obligations under the applicable laws and regulations and shall be
          read in conjunction with our Terms of Use document. This Privacy
          Policy applies to your data which is processed by us through the means
          of our website. We are committed to treating data privacy seriously
          and hence, have provided valuable information in our Privacy Policy in
          relation to the treatment of data provided by you. Our Privacy Policy
          is subject to change at any time without notice. To make sure you are
          aware of any changes, please review this policy periodically.
          <br></br>
          <br></br>
          <b>1. Collection of Data / Processing of data </b>
          <br></br>
          <br></br>
          Categories of data processed by us are as follows:
          <br></br>
          <br></br>
          Demographic & Identity data
          <br></br>
          <br></br>
          <ul>
            <li>
              Contact details such as Name, email address, contact number,
              country, date of birth
            </li>
            <li>
              {" "}
              Details such as transaction amount, Bank Name, Card Type, Card
              number.
            </li>
          </ul>
          Online Identifiers and other Technical Data
          <br></br>
          <br></br>
          <ul>
            <li>
              {" "}
              Location details such as data we get about your location, IP
              address, logs, or from where you connect a computer to the
              internet
            </li>

            <li>
              Technical details such as device information, location and network
              carrier when you use our Mobile App.
            </li>

            <li>
              {" "}
              Communications details such as the Metadata and other data we get
              from communications done through e-mails, SMS, instant messages
              and calls.
            </li>

            <li>
              {" "}
              Usage data details such as data about how you use our Mobile App
              or web-based properties, pages viewed, etc.
            </li>
          </ul>
          By using or continuing to use our Mobile App, you give us
          unconditional consent to collection, use, store and process your
          information (including your personal information) and the data
          generated or collected in the course of your using the Mobile App for
          purpose of transacting sale. The collection and processing of
          information is necessary to perform the agreement we have with you or
          to take steps to enter into an agreement with you and to do all acts
          in relation thereto. You agree and consent to GPL to disclose your
          information, if so required, under applicable law.
          <br></br>
          <br></br>
          <b> 2. Cookies and other trackers used by our digital properties </b>
          <br></br>
          <br></br>
          Mobile Apps use requisite permissions and Software Development Kits
          (SDKs). These are used to make Websites & Apps work, or work more
          efficiently, as well as to provide information to the owners of the
          website/ App. We use cookies, permissions and other trackers in our
          Mobile App that are used to collect and process data about you so we
          can provide you a better online experience as well as improve our
          services.
          <br></br>
          <br></br>
          <b> 3. Disclosure</b>
          <br></br>
          <br></br>
          We may disclose your data to appropriate authorities if we believe
          that it is reasonably necessary to comply with a law, regulation,
          legal process, to protect the safety of any person, to address fraud,
          security, or technical issues, or to protect our rights or the rights
          of those who use our Mobile App.
          <br></br>
          <br></br>
          <b> 4. Data security </b>
          <br></br>
          <br></br>
          We are committed to protecting your data in our custody. We take
          reasonable steps to ensure appropriate physical, technical and
          managerial safeguards are in place to protect your data from
          unauthorized access, alteration, transmission and deletion. We ensure
          that the third parties who provide services to us under appropriate
          contracts, take appropriate security measures to protect your data in
          line with our policies.
          <br></br>
          <br></br>
          <b> 5. Retention and disposal of data</b>
          <br></br>
          <br></br>
          We keep the data we collect about you on our systems or with third
          parties for as long as it is required for the purposes set out in this
          Privacy Policy and for legal or regulatory reasons. We shall take
          reasonable steps to delete or permanently de-identify data that is no
          longer needed.
          <br></br>
          <br></br>
          <b> 6. Limitation of Liability and third parties </b>
          <br></br>
          <br></br>
          Our website may contain links to other websites/apps/softwares of
          other organisations/third parties. We are not liable for any loss or
          damage that may occur to you on account of these third parties.
          <br></br>
        </Modal.Body>
        <Modal.Footer className="modal-footer-sticky "></Modal.Footer>
      </Modal>

      {/* Terms of Use Modal */}
      <Modal
        show={showTermsOfUse}
        onHide={handleCloseTermsOfUse}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton className="modal-header-sticky">
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b> 1. Use of Our Site</b>
          <br></br>
          Welcome to our website (the "Site"). This Site is owned and operated
          by (Godrej Bengal Lamps Limited.site) / its affiliates ("GBL"). The
          Site, including without limitation, all data, information, text,
          graphics, links and other materials is to be used only for the purpose
          of purchase of products being offered by GBL on the Site and for
          attaining information with regard thereto. Your use of the services
          and features of the Site shall be governed by these Terms and
          Conditions (hereinafter "Terms of Use") along with the Privacy Policy
          as modified and amended from time to time. GBL reserves the right to
          change, alter and modify any Terms of Use at its sole discretion.
          Additionally, the prices and availability of the products, services,
          schemes, etc. provided or offered on the Site are subject to change
          without prior notice and at the sole discretion of GBL. By using this
          Site, you hereby consent to the Terms of Use and undertake to be bound
          to all legal obligations arising from the usage of the Site. You
          confirm and understand that the use of the Site creates a legal and
          binding relationship between you and GBL. These legal obligations are
          in addition to any other legal obligations which arise between the
          parties due to sale of products or services.
          <br></br>
          <br></br>
          <b>2. Account </b>
          <br></br>
          You shall be solely responsible for maintaining confidentiality of
          your account, password, and restricting access to your computer/mobile
          device and you hereby accept responsibility for all activities that
          occur under your account and password. You hereby confirm that GBL
          shall not be liable or responsible for any loss / damage of any nature
          whatsoever arising from mis-use of your User ID/password/account
          details or for any unauthorized access of your account. You
          acknowledge that the information you provide, in any manner
          whatsoever, are not confidential or proprietary and does not infringe
          any rights of a third party in whatsoever nature. If we have found a
          breach or suspected breach of the security of your account, we may
          require you to change your password, temporarily or permanently block
          or suspend your account without any liability to GBL. We reserve the
          right to refuse service and/or terminate accounts without prior notice
          if these Terms of Use are violated. You are solely responsible for all
          contents that you download, post, email or otherwise transmit via the
          Site. The information provided to us shall be maintained by us in
          accordance with our Privacy Policy.
          <br></br>
          <br></br>
          <b> 3. Access to Our Site</b>
          <br></br>
          We will do our utmost to ensure that availability of the Site will be
          uninterrupted and that transmissions will be error-free. However, due
          to the nature of the Internet, this cannot be guaranteed. Also, your
          access to the Site may also be occasionally suspended or restricted to
          allow for repairs, maintenance, or the introduction of new facilities
          or services at any time without prior notice. We will attempt to limit
          the frequency and duration of any such suspension or restriction.
          <br></br>
          <br></br>
          <b> 4. Payments </b>
          <br></br>
          Payments may be made by Credit Cards/Net Banking etc. are subject to
          the terms and conditions of the respective bank/financial institution
          and as per applicable laws. The payment gateways being made available
          are provided using the authorized banking infrastructure and credit
          card payment gateway network. In case of any issues such as technical
          failure or errors, the same would be dealt with accordingly, without
          any liability to GBL.
          <br></br>
          <br></br>
          <b>5. Limited License</b>
          <br></br>
          Subject to your compliance with these Terms of Use, GBL grants you
          limited, non-exclusive and non-transferable, license to access, and
          make personal and non-commercial use of the Site. This license does
          not include any resale or commercial use of this Site or its contents.
          All rights not expressly granted to you in these Terms of Use, are
          reserved and retained by the GBL. GBL reserves the right, at any time,
          without notice, and at its sole discretion, to terminate your license
          to use the Site and to block and prevent your future access to the
          Site. Further, you understand that neither GBL, nor any of its
          affiliates and subsidiaries shall be responsible to you for any damage
          or loss occurred to you due to the usage of this Site by you.
          <br></br>
          <br></br>
          <b>6. Your Conduct </b>
          <br></br>
          You must not use the Mobile App in any way that causes, or is likely
          to cause, the Mobile App or access to it to be interrupted, damaged or
          impaired in any way. You shall use the Mobile App for lawful purposes
          only. You must not use the Mobile App for any of the following: for
          fraudulent purposes, or in connection with a criminal offense or other
          unlawful activity; to send, use or reuse any material that does not
          belong to you; or is illegal, offensive, deceptive,misleading,
          abusive, indecent, defamatory, in breach of copyright, trademark,
          confidentiality, privacy orany other proprietary information or right;
          or relates to or promotes money laundering; to cause annoyance,
          inconvenience or needless anxiety.
          <br></br>
          <br></br>
          <b>7. Intellectual Property Rights </b>
          <br></br>
          The Site is the sole property of, and belongs exclusively to Godrej
          Bengal Lamps Limited. You are prohibited from using GBL’s name, logo,
          colour scheme and other trademarks. By allowing use of this Site, no
          license to use GBL’s intellectual property is being provided. GBL
          expressly and exclusively reserves all the intellectual property
          rights in all text, programs, products, processes, technology,
          content, software and other materials, which appear on this Site.
          Consequently, the materials on this Site shall not be copied,
          reproduced, duplicated, republished, downloaded, posted, transmitted,
          distributed or modified in whole or in part or in any other form
          whatsoever. No right, title or interest in any downloaded materials or
          software is transferred to you as a result of any such downloading or
          copying, reproducing, duplicating, republishing, posting,
          transmitting, distributing or modifying.
          <br></br>
          <br></br>
          <b>8. Links and third party sites </b>
          <br></br>
          References on the Mobile App to any names, marks, products or services
          of third parties or hypertext links to third party sites / softwares
          of third party or information are provided solely as a convenience to
          you. GPL is not responsible for the content of any third party
          sites/apps/softwares and does not make any representations regarding
          the content or accuracy of material on such sites/apps. If you decide
          to link to any such third party websites/apps, you do so entirely at
          your own risk.
          <br></br>
          <br></br>
          <b>9. Data Encryption </b>
          <br></br>
          The Site is the sole property of, and belongs exclusively to Godrej
          Bengal Lamps Limited. You are prohibited from using GBL’s name, logo,
          colour scheme and other trademarks. By allowing use of this Site, no
          license to use GBL’s intellectual property is being provided. GBL
          expressly and exclusively reserves all the intellectual property
          rights in all text, programs, products, processes, technology,
          content, software and other materials, which appear on this Site.
          Consequently, the materials on this Site shall not be copied,
          reproduced, duplicated, republished, downloaded, posted, transmitted,
          distributed or modified in whole or in part or in any other form
          whatsoever. No right, title or interest in any downloaded materials or
          software is transferred to you as a result of any such downloading or
          copying, reproducing, duplicating, republishing, posting,
          transmitting, distributing or modifying.
          <br></br>
          <br></br>
          <b>10. Termination </b>
          <br></br>
          GBL may terminate the Terms of Use at any time and may do so without
          notice, and accordingly deny you access to the Site. Upon any
          termination of the Terms of Use, you shall promptly destroy all
          materials downloaded or otherwise obtained from this Site, as well as
          all copies of such materials, whether made under the Terms of Use or
          otherwise. Any such termination of the Terms of Use shall not dilute
          your obligation to pay for the product/unit purchased from the Site or
          affect any liability that may have arisen under the Terms of Use and
          that all the terms and conditions of the Application Form, Allotment
          Letter, Agreement for Sale and any document relating to the sale of
          the product/unit shall be binding on you.
          <br></br>
          <br></br>
          <b>11. Indemnity </b>
          <br></br>
          You agree to defend, indemnify and hold harmless GBL, its employees,
          directors, officers, agents and their successors and assigns, its
          holding, subsidiaries, affiliates, partners from and against any and
          all claims, liabilities, damages, losses, costs and expenses,
          including attorney's fees, caused by or arising out of your actions or
          inactions, which may result in any loss or liability to GBL or any
          third party including but not limited due to breach of any warranties,
          representations or undertakings or in relation to the non-fulfilment
          of any of your obligations under the Terms of Use or under the
          Application Form, Allotment Letter, Agreement for Sale and any such
          document relating to the sale transaction of the product/unit, or
          arising out of the your violation of any applicable laws, regulations
          including but not limited to Intellectual Property Rights, payment of
          statutory dues and taxes, violation of rights of privacy or publicity.
          This clause shall survive the expiry or termination of the Terms of
          Use.
          <br></br>
          <br></br>
          <b>12. Disclaimer </b>
          <br></br>
          You acknowledge and undertake that you are accessing the services on
          the Site and transacting at your own risk and are using your best and
          prudent judgment before entering into any transactions through the
          Site. You further acknowledge and undertake that you will use the Site
          to purchase products or transact in relation to the unit/product only
          for your personal use and not for business purposes. GBL does not take
          any responsibility for decisions taken by you including on the
          information provided in this Site. GBL expressly disclaims any
          liability and / or warranties of any kind, whether express or implied.
          GBL does not warrant or assume any liability or responsibility for the
          completeness, accuracy, errors, usage or usefulness of any information
          or content and / or for any actions or inactions of the user. The user
          acknowledge/s that all terms and conditions of the Application Form,
          Allotment Letter and Agreement for Sale and any document relating to
          the sale of the product/unit as agreed shall be valid and binding. The
          user hereby indemnify and hold harmless the GBL against any claims,
          damages, actions, demands, liabilities suffered by GBL including the
          usage of this Site by you.
          <br></br>
          <br></br>
          <b>13. Governing Law and Dispute </b>
          <br></br>
          Resolution The Terms of Use shall be governed by the laws of India and
          subject to exclusive jurisdiction of the courts at Mumbai. In case of
          any conflict, the parties agree to resolve the dispute, first by
          mutual discussions, failing which the same can be resolved through
          arbitration as per the provisions of Arbitration and Conciliation Act,
          1996. The venue of arbitration shall always be Mumbai.
          <br></br>
          <br></br>
          <b>14. Grievance Officer</b>
          <br></br>
          For any grievances and / or discrepancies please write to grievance
          officer. The contact details are provided below:
          <br></br>Mr. ExamplePhone:
          +918048285923Email:example@godrejproperties.com
          (mailto:example@godrejproperties.com)
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </footer>
  );
}

export default Footer;
